





























import Vue from 'vue';

interface IBoxShippingData {
  'Address': string;
  'Customer': string;
  'Customer no.': string;
  'Email': string;
  'Tracking no.'?: string;
  'trackingLink'?: string;
}

export default Vue.extend({
  name: 'BoxShippingData',
  data() {
    return {};
  },
  props: {
    boxShippingData: {
      type: Object as () => IBoxShippingData,
      required: true,
    },
  },
  methods: {
    isTrackingNo(key: string) {
      return key === this.$t('box-details.tracking-number');
    },
    isNoTrackingNumberAndLink(key: string) {
      return key !== this.$t('box-details.tracking-number') && key !== 'trackingLink';
    },
  },
});
